///////////////////////////////////////////////////////////
// File        : _Template.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import Header from "~/Components/Layouts/Header/Header";
import Footer from "~/Components/Layouts/Footer/Footer";

// Function Definition
function Template(props) {
  // console.info("_Template.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id, children} = props;
  const [header, setHeader] = useState("");
  const [footer, setFooter] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.header && setHeader(<Header id={fields.header.sys.id} />);
            fields.footer && setFooter(<Footer id={fields.footer.sys.id} />);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <>
      {header}
      {children}
      {footer}
    </>
  );
}

// Interface
Template.propTypes = Object.assign({}, defaultComponentPropTypes, {
  children: PropTypes.object,
});
Template.defaultProps = Object.assign({}, defaultComponentProps, {
  children: null,
});

// Exports

export default Template;

